<template>
  <div class="brands-dashboard-information" :class="{ 'brands-dashboard-information--dark': $vuetify.theme.dark }">
    <clients-dashboard-block
      :title="$t('brands.dashboard.information.title')"
      :subtitle="informations.name"
      :with-large-header="true"
      :action="{
        display: true,
        links: [
          {
            label: $t('brands.dashboard.information.goToClient'),
            to: { name: 'ClientsDashboard', params: { id: client.uuid } },
          },
        ],
        mode: 'link',
      }"
    >
      <template v-slot:body>
        <div class="brands-dashboard-information__grid">
          <ui-block
            id="nameLabel"
            class="required-asterisk"
            :label="$t('brands.dashboard.information.form.name.label')"
          >
            <template v-slot:body>
              <v-text-field
                v-model="modelInformation.name"
                id="name"
                solo
                flat
                outlined
                dense
                :placeholder="$t('brands.dashboard.information.form.name.placeholder')"
                :error-messages="simpleErrors('modelInformation', 'name')"
              />
            </template>
          </ui-block>
          <ui-block id="clientLabel" :label="$t('brands.dashboard.information.form.client.label')">
            <template v-slot:body>
              <v-text-field
                :value="client.name"
                id="client"
                solo
                flat
                readonly
                dense
                :placeholder="$t('brands.dashboard.information.form.client.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block
            id="defaultLocaleIsoCodeLabel"
            :label="$t('brands.dashboard.information.form.defaultLocaleIsoCode.label')"
          >
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.defaultLocaleIsoCode"
                id="defaultLocaleIsoCode"
                solo
                flat
                outlined
                dense
                item-value="code"
                item-text="name"
                :items="locales"
                :placeholder="$t('brands.dashboard.information.form.defaultLocaleIsoCode.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block id="idbel" :label="$t('brands.dashboard.information.form.id.label')">
            <template v-slot:body>
              <v-text-field
                v-model="modelInformation.id"
                id="id"
                solo
                flat
                readonly
                dense
                :placeholder="$t('brands.dashboard.information.form.id.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block
            id="defaultCountryIsoCodeLabel"
            :label="$t('brands.dashboard.information.form.defaultCountryIsoCode.label')"
          >
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.defaultCountryIsoCode"
                id="defaultCountryIsoCode"
                solo
                flat
                outlined
                dense
                item-value="0"
                item-text="1"
                :items="Object.entries(availablesCountries)"
                :placeholder="$t('brands.dashboard.information.form.defaultCountryIsoCode.placeholder')"
              />
            </template>
          </ui-block>
        </div>
        <ui-block id="mediaPathLabel" :label="$t('brands.dashboard.information.form.mediaPath.label')">
          <template v-slot:body>
            <v-text-field
              v-model="modelInformation.mediaPath"
              id="mediaPath"
              readonly
              solo
              flat
              outlined
              dense
              :placeholder="$t('brands.dashboard.information.form.mediaPath.placeholder')"
              :append-icon="icons.mdiContentCopy"
              @click:append="copyToClipboard(modelInformation.mediaPath)"
            />
          </template>
        </ui-block>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { copyToClipboard } from '@/utils/utilities.util'
import { validationMixin } from 'vuelidate'
import ErrorsMixin from '@/mixins/errors.mixin'
import { required } from 'vuelidate/lib/validators'
import { mdiContentCopy } from '@mdi/js'

import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'BrandsDashboardInformation',
  components: {
    ClientsDashboardBlock,
    UiBlock,
  },
  mixins: [validationMixin, ErrorsMixin],
  props: {
    informations: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiContentCopy,
      },
      modelInformation: {
        id: '',
        name: '',
        client: '',
        defaultLocaleIsoCode: null,
        defaultCountryIsoCode: null,
        mediaPath: '',
      },
    }
  },
  created() {
    this.modelInformation = {
      ...this.modelInformation,
      ...this.informations,
    }
  },
  computed: {
    ...mapState({
      locales: state => state.backoffice.locales,
      availablesCountries: state => state.gmb.availablesCountries,
    }),
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async copyToClipboard(string) {
      try {
        await copyToClipboard(string)
        this.setAlert({
          color: 'success',
          text: this.$t('notification.success.copy'),
        })
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
    },
  },
  validations() {
    return {
      modelInformation: {
        name: {
          required,
        },
      },
    }
  },
}
</script>

<style scoped lang="scss">
.brands-dashboard-information {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }

  &__grid {
    @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full;
  }
}
</style>
