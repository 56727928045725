<template>
  <v-fade-transition group leave-absolute>
    <v-overlay key="overlay" :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6" />
    </v-overlay>
    <form class="brands-dashboard" @submit.prevent="onSubmit" novalidate autocomplete="off" key="form" v-else>
      <div class="sm:tw-grid sm:tw-grid-cols-4 tw-gap-6">
        <brands-dashboard-information
          class="tw-col-span-2"
          :informations="brandInformations"
          :client="currentClient"
          ref="information"
        />
        <brands-dashboard-identity
          class="brands-dashboard__col tw-col-span-2"
          :identity="brandIdentity"
          ref="identity"
        />
      </div>

      <div class="sm:tw-grid sm:tw-grid-flow-col tw-gap-6">
        <brands-dashboard-images
          class="brands-dashboard__images"
          ref="images"
          :medias-types="mediasTypeAvailable"
          :medias-client="currentMedias"
          @open="media => openModalMedia({ media, origin: currentBrand.identifier })"
        />
      </div>
      <v-btn
        bottom
        right
        fixed
        class="tw-mx-auto"
        type="submit"
        color="#449afd"
        dark
        :loading="saving"
        :disabled="saving"
      >
        {{ $t('button.save') }}
      </v-btn>
    </form>
    <ui-modal-media
      key="media"
      v-if="openModal === true"
      :config="configModal"
      :medias-client="currentMedias"
      :saving="savingMedia"
      @save="saveMedia"
      @close="openModal = false"
      @delete="deleteMedia"
    />
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { cleanString } from '@/utils/utilities.util'
import MediaModalMixin from '@/mixins/mediaModal.mixin'

import BrandsDashboardInformation from '@/components/brands/Dashboard/Information.vue'
import BrandsDashboardIdentity from '@/components/brands/Dashboard/Identity.vue'
import BrandsDashboardImages from '@/components/brands/Dashboard/Images.vue'
import UiModalMedia from '@/components/UI/Modal/Media.vue'

export default {
  name: 'BrandsDashboard',
  components: {
    BrandsDashboardInformation,
    BrandsDashboardIdentity,
    BrandsDashboardImages,
    UiModalMedia,
  },
  mixins: [MediaModalMixin],
  async created() {
    this.setUpdating(true)
    await this.getBrandCore({ params: { uuid: this.$route.params.id } })
    await Promise.all([
      this.getClientCore({ uuid: cleanString(this.currentBrand.client, 'clients/') }),
      this.getMedias({
        query: [
          { key: 'origin', value: this.currentBrand.identifier },
          { key: 'status', value: true },
        ],
      }),
      this.getMediasType({
        query: [
          { key: 'allowedEntities.name', value: 'market' },
          { key: 'uploadable', value: 'true' },
          { key: 'status', value: 'true' },
        ],
      }),
    ])
    this.setUpdating(false)
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapState({
      currentMedias: state => state.media.currentMedias,
      mediasTypeAvailable: state => state.media.mediasTypeAvailable,

      updating: state => state.backoffice.updating,
      currentBrand: state => state.brand.currentBrand,
      currentClient: state => state.client.currentClient,
    }),
    brandInformations() {
      const { name, client, defaultLocaleIsoCode, defaultCountryIsoCode, id, country, mediaPath } = this.currentBrand
      return { name, client, defaultLocaleIsoCode, defaultCountryIsoCode, id, country, mediaPath }
    },
    brandIdentity() {
      const { brandAccent, brandColor, brandDarken, brandLighten } = this.currentBrand.customFields?.identity || {}
      return { brandAccent, brandColor, brandDarken, brandLighten }
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      setUpdating: 'backoffice/setUpdating',

      getBrandCore: 'brand/getBrandCore',
      patchBrand: 'brand/patchBrand',

      getClientCore: 'client/getClientCore',

      getMediasType: 'media/getMediasType',
      getMedias: 'media/getMedias',
    }),
    async onSubmit() {
      const brand = {
        ...this.$refs.information.modelInformation,
        customFields: { ...this.currentBrand.customFields, identity: this.$refs.identity.modelIdentity },
      }
      this.saving = true
      this.$refs.information.$v.$touch()

      try {
        if (!this.$refs.information.$v.$invalid) {
          await this.patchBrand({ brand, params: { uuid: this.currentBrand.uuid } })
          this.setAlert({
            color: 'success',
            text: this.$t('notification.update.brand', { name: this.currentBrand.name }),
          })
        }
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.saving = false
    },
  },
}
</script>

<style lang="scss" scoped>
.brands-dashboard {
  @apply sm:tw-p-8 tw-flex tw-flex-col tw-gap-4;

  &__col {
    height: fit-content;
  }

  &__images {
    width: inherit;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      width: fit-content;
    }
  }
}
</style>
