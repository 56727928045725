var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brands-dashboard-identity",class:{ 'brands-dashboard-identity--dark': _vm.$vuetify.theme.dark }},[_c('clients-dashboard-block',{attrs:{"icon-header":_vm.icons.mdiPalette,"title":_vm.$t('brands.dashboard.identity.title'),"action":{ display: true, label: _vm.$t('clients.dashboard.identity.switch'), mode: 'switch', value: _vm.colorIsHexa }},on:{"onAction":_vm.updateColorMode},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"brands-dashboard-identity__grid"},[_c('ui-block',{attrs:{"id":"brandAccentLabel","label":_vm.$t('brands.dashboard.identity.form.brandAccent.label'),"action":{
            display: true,
            label: `${_vm.$t('button.copy')} ${_vm.$t(`color.${_vm.colorIsHexa ? 'hexa' : 'rgba'}`)}`,
            icon: _vm.icons.mdiContentCopy,
            mode: 'btn',
          }},on:{"onAction":function($event){return _vm.getColorCopy(_vm.modelIdentity.brandAccent, 'avatar-color-brand-accent')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-color-picker',{attrs:{"id":'avatar-color-brand-accent',"current-color":_vm.modelIdentity.brandAccent,"color-mode":_vm.colorMode,"placeholder":_vm.$t('brands.dashboard.identity.form.brandAccent.placeholder')},on:{"colorUpdate":color => (_vm.modelIdentity.brandAccent = color)}})]},proxy:true}])}),_c('ui-block',{attrs:{"action":{
            display: true,
            label: `${_vm.$t('button.copy')} ${_vm.$t(`color.${_vm.colorIsHexa ? 'hexa' : 'rgba'}`)}`,
            icon: _vm.icons.mdiContentCopy,
            mode: 'btn',
          },"id":"brandColorLabel","label":_vm.$t('brands.dashboard.identity.form.brandColor.label')},on:{"onAction":function($event){return _vm.getColorCopy(_vm.modelIdentity.brandColor, 'avatar-color-brand-color')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-color-picker',{attrs:{"id":'avatar-color-brand-color',"current-color":_vm.modelIdentity.brandColor,"color-mode":_vm.colorMode,"placeholder":_vm.$t('brands.dashboard.identity.form.brandColor.placeholder')},on:{"colorUpdate":color => (_vm.modelIdentity.brandColor = color)}})]},proxy:true}])}),_c('ui-block',{attrs:{"action":{
            display: true,
            label: `${_vm.$t('button.copy')} ${_vm.$t(`color.${_vm.colorIsHexa ? 'hexa' : 'rgba'}`)}`,
            icon: _vm.icons.mdiContentCopy,
            mode: 'btn',
          },"id":"brandDarkenLabel","label":_vm.$t('brands.dashboard.identity.form.brandDarken.label')},on:{"onAction":function($event){return _vm.getColorCopy(_vm.modelIdentity.brandDarken, 'avatar-color-brand-darken')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-color-picker',{attrs:{"id":'avatar-color-brand-darken',"current-color":_vm.modelIdentity.brandDarken,"color-mode":_vm.colorMode,"placeholder":_vm.$t('brands.dashboard.identity.form.brandDarken.placeholder')},on:{"colorUpdate":color => (_vm.modelIdentity.brandDarken = color)}})]},proxy:true}])}),_c('ui-block',{attrs:{"action":{
            display: true,
            label: `${_vm.$t('button.copy')} ${_vm.$t(`color.${_vm.colorIsHexa ? 'hexa' : 'rgba'}`)}`,
            icon: _vm.icons.mdiContentCopy,
            mode: 'btn',
          },"id":"brandLightenLabel","label":_vm.$t('brands.dashboard.identity.form.brandLighten.label')},on:{"onAction":function($event){return _vm.getColorCopy(_vm.modelIdentity.brandLighten, 'avatar-color-brand-lighten')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-color-picker',{attrs:{"id":'avatar-color-brand-lighten',"current-color":_vm.modelIdentity.brandLighten,"color-mode":_vm.colorMode,"placeholder":_vm.$t('brands.dashboard.identity.form.brandLighten.placeholder')},on:{"colorUpdate":color => (_vm.modelIdentity.brandLighten = color)}})]},proxy:true}])})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }