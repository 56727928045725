<template>
  <div class="brands-dashboard-identity" :class="{ 'brands-dashboard-identity--dark': $vuetify.theme.dark }">
    <clients-dashboard-block
      :icon-header="icons.mdiPalette"
      :title="$t('brands.dashboard.identity.title')"
      :action="{ display: true, label: $t('clients.dashboard.identity.switch'), mode: 'switch', value: colorIsHexa }"
      @onAction="updateColorMode"
    >
      <template v-slot:body>
        <div class="brands-dashboard-identity__grid">
          <ui-block
            id="brandAccentLabel"
            :label="$t('brands.dashboard.identity.form.brandAccent.label')"
            :action="{
              display: true,
              label: `${$t('button.copy')} ${$t(`color.${colorIsHexa ? 'hexa' : 'rgba'}`)}`,
              icon: icons.mdiContentCopy,
              mode: 'btn',
            }"
            @onAction="getColorCopy(modelIdentity.brandAccent, 'avatar-color-brand-accent')"
          >
            <template v-slot:body>
              <ui-color-picker
                :id="'avatar-color-brand-accent'"
                :current-color="modelIdentity.brandAccent"
                :color-mode="colorMode"
                :placeholder="$t('brands.dashboard.identity.form.brandAccent.placeholder')"
                @colorUpdate="color => (modelIdentity.brandAccent = color)"
              />
            </template>
          </ui-block>
          <ui-block
            :action="{
              display: true,
              label: `${$t('button.copy')} ${$t(`color.${colorIsHexa ? 'hexa' : 'rgba'}`)}`,
              icon: icons.mdiContentCopy,
              mode: 'btn',
            }"
            @onAction="getColorCopy(modelIdentity.brandColor, 'avatar-color-brand-color')"
            id="brandColorLabel"
            :label="$t('brands.dashboard.identity.form.brandColor.label')"
          >
            <template v-slot:body>
              <ui-color-picker
                :id="'avatar-color-brand-color'"
                :current-color="modelIdentity.brandColor"
                :color-mode="colorMode"
                :placeholder="$t('brands.dashboard.identity.form.brandColor.placeholder')"
                @colorUpdate="color => (modelIdentity.brandColor = color)"
              />
            </template>
          </ui-block>
          <ui-block
            :action="{
              display: true,
              label: `${$t('button.copy')} ${$t(`color.${colorIsHexa ? 'hexa' : 'rgba'}`)}`,
              icon: icons.mdiContentCopy,
              mode: 'btn',
            }"
            @onAction="getColorCopy(modelIdentity.brandDarken, 'avatar-color-brand-darken')"
            id="brandDarkenLabel"
            :label="$t('brands.dashboard.identity.form.brandDarken.label')"
          >
            <template v-slot:body>
              <ui-color-picker
                :id="'avatar-color-brand-darken'"
                :current-color="modelIdentity.brandDarken"
                :color-mode="colorMode"
                :placeholder="$t('brands.dashboard.identity.form.brandDarken.placeholder')"
                @colorUpdate="color => (modelIdentity.brandDarken = color)"
              />
            </template>
          </ui-block>
          <ui-block
            :action="{
              display: true,
              label: `${$t('button.copy')} ${$t(`color.${colorIsHexa ? 'hexa' : 'rgba'}`)}`,
              icon: icons.mdiContentCopy,
              mode: 'btn',
            }"
            @onAction="getColorCopy(modelIdentity.brandLighten, 'avatar-color-brand-lighten')"
            id="brandLightenLabel"
            :label="$t('brands.dashboard.identity.form.brandLighten.label')"
          >
            <template v-slot:body>
              <ui-color-picker
                :id="'avatar-color-brand-lighten'"
                :current-color="modelIdentity.brandLighten"
                :color-mode="colorMode"
                :placeholder="$t('brands.dashboard.identity.form.brandLighten.placeholder')"
                @colorUpdate="color => (modelIdentity.brandLighten = color)"
              />
            </template>
          </ui-block>
        </div>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiContentCopy, mdiPalette } from '@mdi/js'
import { copyToClipboard } from '@/utils/utilities.util'

import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiColorPicker from '@/components/UI/ColorPicker.vue'

export default {
  name: 'BrandsDashboardIdentity',
  components: {
    ClientsDashboardBlock,
    UiBlock,
    UiColorPicker,
  },
  props: {
    identity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiContentCopy,
        mdiPalette,
      },
      modelIdentity: {
        brandAccent: '',
        brandColor: '',
        brandDarken: '',
        brandLighten: '',
      },
      colorMode: 'hexa',
    }
  },
  created() {
    this.modelIdentity = {
      ...this.modelIdentity,
      ...this.identity,
    }
  },
  computed: {
    colorIsHexa() {
      return this.colorMode === 'hexa'
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async copyToClipboard(string) {
      try {
        await copyToClipboard(string)
        this.setAlert({
          color: 'success',
          text: this.$t('notification.success.copy'),
        })
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
    },
    updateColorMode(status) {
      this.colorMode = status ? 'hexa' : 'rgba'
    },
    getColorCopy(colorHexa, id) {
      if (this.colorIsHexa) {
        this.copyToClipboard(colorHexa)
      } else {
        const target = document.getElementById(id)
        this.copyToClipboard(window.getComputedStyle(target).backgroundColor.match(/\((.*?)\)/)[1])
      }
    },
  },
}
</script>

<style scoped lang="scss">
.brands-dashboard-identity {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }

  &__grid {
    @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full;
  }
}
</style>
